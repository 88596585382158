import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../styles/accueil.scss';

const Formation = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <section id="formation">
      <img className="logoHome" src="images/logo/ut2j.png" alt=""></img>
      <h3>{T["forma2"][language]}</h3>
      <p>{T["forma3"][language]}</p>
      <aside className="poppyPup">
        <p>
          <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24"><path d="M21,10h-2V4h1V2h-2H6H4v2h1v6H3c-0.553,0-1,0.448-1,1v7v1v1h1h2h1h12h1h2h1v-1v-1v-7C22,10.448,21.553,10,21,10z M14,18v-4 h-4v4H7V4h10v14H14z"></path><path d="M9 6H11V8H9zM13 6H15V8H13zM9 10H11V12H9zM13 10H15V12H13z"></path></svg>
          {T["forma1"][language]}<br></br><svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" fill="currentColor" class="bi bi-arrow-down-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14 13.5a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1 0-1h4.793L2.146 2.854a.5.5 0 1 1 .708-.708L13 12.293V7.5a.5.5 0 0 1 1 0v6z"/>
</svg></p>
      </aside> 
    </section>
  );
}

export default Formation;
