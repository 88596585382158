import { BrowserRouter as Router, Route} from 'react-router-dom';
import Accueil from './components/Accueil';
import Header from './components/Header';
import Footer from './components/Footer';
import Background from './components/Background';
import Projets from "./components/Projets";
import Jjoo from "./components/projets/Jjoo";
import Dranora from './components/projets/Dranora';
import Polemos from './components/projets/Polemos';
import Contact from'./components/Contact';

import LanguageContainer from './containers/languageContainer';

const Hub = () => {
  return (
    <Router>
      <LanguageContainer />
      <Route exact path="/">
        <Accueil />
      </Route>
      <Route exact path="/Projects">
        <Projets />
      </Route>
      <Route path="/Projects/jjoo">
        <Jjoo />
      </Route>
      <Route path="/Projects/dranora">
        <Dranora />
      </Route>
      <Route path="/Projects/polemos">
        <Polemos />
      </Route>
      <Route path="/Contact">
        <Contact />
      </Route>
      <Footer />
      <Background/>
    </Router>
    );

}

export default Hub;
