import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './../styles/header.scss';
import { useSelector } from 'react-redux';

const Header = ({dispatchLanguage}) => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <nav id="mainMenu">
      <section id="left">
        <img src="/images/logo/logo.png"></img>
        <ul>
          <li><Link to="/"><svg className="svgLeft upper" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M21.743,12.331l-9-10c-0.379-0.422-1.107-0.422-1.486,0l-9,10c-0.265,0.293-0.331,0.715-0.17,1.076 C2.247,13.768,2.605,14,3,14h2v7c0,0.552,0.447,1,1,1h3c0.553,0,1-0.448,1-1v-4h4v4c0,0.552,0.447,1,1,1h3c0.553,0,1-0.448,1-1v-7h2 c0.395,0,0.753-0.232,0.913-0.593C22.074,13.046,22.008,12.625,21.743,12.331z"></path></svg><i>{T["Home"][language]}</i></Link></li>
          <li className="submenuParent">
            <a target="_blank" href="/cv.pdf"><svg className="svgLeft upper" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651C8.971 14.9 8.717 14.68 8.267 14.68zM11.754 14.692c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396C13.015 15.13 12.53 14.692 11.754 14.692z"></path><path d="M14,2H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2V8L14,2z M9.498,16.19 c-0.309,0.29-0.765,0.42-1.296,0.42c-0.119,0-0.226-0.006-0.308-0.018v1.426H7v-3.936C7.278,14.036,7.669,14,8.219,14 c0.557,0,0.953,0.106,1.22,0.319c0.254,0.202,0.426,0.533,0.426,0.923C9.864,15.634,9.734,15.965,9.498,16.19z M13.305,17.545 c-0.42,0.349-1.059,0.515-1.84,0.515c-0.468,0-0.799-0.03-1.024-0.06v-3.917C10.772,14.029,11.204,14,11.66,14 c0.757,0,1.249,0.136,1.633,0.426c0.415,0.308,0.675,0.799,0.675,1.504C13.968,16.693,13.689,17.22,13.305,17.545z M17,14.77 h-1.532v0.911h1.432v0.734h-1.432v1.604h-0.906v-3.989H17V14.77z M14,9c-0.553,0-1,0-1,0V4l5,5H14z"></path></svg><i>{T["CV"][language]}</i></a>
            <ul>
              <li><a target="_blank" href="/cv.pdf"><p>Version française</p><img src="/images/flags/FR.png" alt="french flag"></img></a></li>
              <li><a target="_blank" href="/cv_en.pdf"><p>English version</p><img src="/images/flags/GB.png" alt="english flag"></img></a></li>
            </ul>
          </li>
          <li><Link to="/Projects"><svg className="svgLeft" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M19 10H5c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-8C21 10.897 20.103 10 19 10zM5 6H19V8H5zM7 2H17V4H7z"></path></svg><i>{T["Projets"][language]}</i></Link></li>
          <li><Link to="/Contact"><svg className="svgLeft" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><circle cx="9.5" cy="9.5" r="1.5"></circle><circle cx="14.5" cy="9.5" r="1.5"></circle><path d="M12,2C6.486,2,2,5.589,2,10c0,2.908,1.897,5.515,5,6.934V22l5.34-4.004C17.697,17.852,22,14.32,22,10 C22,5.589,17.514,2,12,2z M12,16h-0.333L9,18v-2.417l-0.641-0.247C5.671,14.301,4,12.256,4,10c0-3.309,3.589-6,8-6s8,2.691,8,6 S16.411,16,12,16z"></path></svg><i>{T["Contact"][language]}</i></Link></li>
        </ul>
      </section> 
      <section id="right" className="submenuParent">
        <img src={language === 0 ? '/images/flags/FR.png' : language === 1 ? '/images/flags/GB.png': '/images/flags/PL.png'} alt="main flag"></img>
        <p><svg className="downer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M19.931,11h-2.764 c-0.116-2.165-0.73-4.3-1.792-6.243C17.813,5.898,19.582,8.228,19.931,11z M12.53,4.027c1.035,1.364,2.427,3.78,2.627,6.973H9.03 c0.139-2.596,0.994-5.028,2.451-6.974C11.653,4.016,11.825,4,12,4C12.179,4,12.354,4.016,12.53,4.027z M8.688,4.727 C7.704,6.618,7.136,8.762,7.03,11H4.069C4.421,8.204,6.217,5.857,8.688,4.727z M4.069,13h2.974c0.136,2.379,0.665,4.478,1.556,6.23 C6.174,18.084,4.416,15.762,4.069,13z M11.45,19.973C10.049,18.275,9.222,15.896,9.041,13h6.113 c-0.208,2.773-1.117,5.196-2.603,6.972C12.369,19.984,12.187,20,12,20C11.814,20,11.633,19.984,11.45,19.973z M15.461,19.201 c0.955-1.794,1.538-3.901,1.691-6.201h2.778C19.587,15.739,17.854,18.047,15.461,19.201z"></path></svg><i>{T["Language"][language]}</i></p>
        <ul>
          <li onClick={() => dispatchLanguage(0)}><img src="/images/flags/FR.png" alt="fmagerench flag"></img> Français</li>
          <li onClick={() => dispatchLanguage(1)}><img src="/images/flags/GB.png" alt="english flag"></img> English</li>
          <li onClick={() => dispatchLanguage(2)}><img src="/images/flags/PL.png" alt="polish flag"></img> Polski</li>
        </ul>
      </section>


    </nav>
    );

}

export default Header;
