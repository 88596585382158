import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../styles/accueil.scss';

const DevInfo = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <article id="devinfo" className="passion">
      <section>
        <h4>{T["devinfo1"][language]}</h4>
        <ul>
          <li><strong>{T["devinfo2"][language]}</strong>
            <ul>
              <li><b>2021</b> {T["devinfo3"][language]}</li>
              <li><b>2018</b> {T["devinfo4"][language]}</li>
            </ul>
          </li>
          <li><strong>{T["devinfo5"][language]}</strong>
            <ul>
              <li><b>2021</b> {T["devinfo6"][language]}<br></br>
              - {T["devinfo7"][language]}<br></br>
              - {T["devinfo8"][language]}<br></br>
              - {T["devinfo9"][language]}<br></br>
              - {T["devinfo10"][language]}</li>
              <li><b>2019-2020</b> {T["devinfo11"][language]}</li>
              <li><b>2019</b> {T["devinfo12"][language]}</li> 
            </ul>
          </li>
        </ul>
        {/* <button className="plus">En voir plus <svg className="svgRight downer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
</svg></button> */}
      </section>
      <img className="previewLeft" src="images/preview/web.jpg"></img>
      <aside className="svgBGl"><svg xmlns="http://www.w3.org/2000/svg" width="584" height="584" viewBox="0 0 24 24" fill="currentColor"><path d="M7.375 16.781l1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4C2.138 11.409 2 11.696 2 12s.138.591.375.781L7.375 16.781zM16.625 7.219l-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4C21.862 12.591 22 12.304 22 12s-.138-.591-.375-.781L16.625 7.219z"></path><path transform="rotate(102.527 12 12)" d="M2.78 11H21.219V13.001H2.78z"></path></svg></aside>
      <aside className="blackwood"></aside>
      <aside id="revoWood" className="blackwood"></aside>
      
    </article>
  );
}

export default DevInfo;
