import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../../styles/projet.scss';


const Polemos = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <main id="projet">
      <h1>Polemos - {T["polemosH0"][language]}</h1>
      <section>
        <img src="./../images/logo/polemos.png" alt="polemos"></img>
        <article>
          <h2>{T["dranoraTitlePresentation"][language]}</h2>
          <p>
            <strong>Polemos</strong> {T["polemosH1"][language]} <strong>Dragonica</strong> {T["polemosH1p1"][language]}<br></br>
            {T["polemosH2"][language]} <strong>Node.js</strong> {T["polemosH3"][language]}<br></br>
            {T["polemosH4"][language]} <strong>React.js</strong> {T["with"][language]} <strong>Redux</strong> {T["polemosH5"][language]}
          </p>
          <ul>
            <li>{T["polemosH6"][language]}</li>
            <li>{T["polemosH7"][language]}</li>
            <li>{T["polemosH8"][language]}</li>
            <li>{T["polemosH9"][language]}</li>
            <li>{T["polemosH10"][language]}</li>
          </ul>     
        </article>
        <article>
          <h2>{T["polemosH11"][language]}</h2>
          <p>
          <strong>{T["polemosH12"][language]}</strong><br></br><br></br>
          {T["polemosH13"][language]}
            <img src="./../images/preview/polemos/profile.png" alt="image 00"></img>
          </p> 
          <p>
          <strong>{T["polemosH14"][language]}</strong><br></br><br></br>
          {T["polemosH15"][language]}
            <img src="./../images/preview/polemos/classement.png" alt="image 01"></img>
            <img src="./../images/preview/polemos/rankedImpossible.png" alt="02"></img>
            <img src="./../images/preview/polemos/rankedFound.png" alt="ui 03"></img>
            <img src="./../images/preview/polemos/rankedSelect.jpg" alt="ui 04"></img>
            <img src="./../images/preview/polemos/rankedMatch.png" alt="ui 05"></img>
          </p>
          <p>
          <strong>{T["polemosH16"][language]}</strong><br></br><br></br>
          {T["polemosH17"][language]}
            <img src="./../images/preview/polemos/rankedShop1.png" alt="shop 1"></img>
            <img src="./../images/preview/polemos/rankedShop2.png" alt="shop 2"></img>
            <img src="./../images/preview/polemos/rankedShop3.png" alt="shop 3"></img>
          </p>
          <p>
          <strong>{T["polemosH18"][language]}</strong><br></br><br></br>
          {T["polemosH19"][language]}
            <img src="./../images/preview/polemos/pass1.png" alt="pass 1"></img>
            <img src="./../images/preview/polemos/pass2.png" alt="pass 2"></img>
          </p> 
          <p>
          <strong>{T["polemosH20"][language]}</strong><br></br><br></br>
          {T["polemosH21"][language]}
            <img src="./../images/preview/polemos/rankedTeams.png" alt="team 1"></img>
          </p> 
        </article>

      </section>
      <aside id="revoWood" className="blackwood"></aside>
    </main>
  );
}

export default Polemos;
