import {connect} from "react-redux";
import Header from "../components/Header";
import {saveLanguage} from '../actions/Actions';

const mapDispatchToProps = (dispatch) => ({
    dispatchLanguage: (language) => {
        console.log(language);
        dispatch(saveLanguage(language))
    }
});



export default connect(null, mapDispatchToProps)(Header);