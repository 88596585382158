import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../styles/accueil.scss';
import Presentation from './../components/Presentation';
import Formation from './../components/Formation';
import Entreprise from './../components/Entreprise';
import DevInfo from './../components/DevInfo';
import JeuVideo from './../components/JeuVideo';
import LevelDesign from './../components/LevelDesign';
import Modelisation from './../components/Modelisation';
import GameDesign from './../components/GameDesign';

const Accueil = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <main>
      <Presentation />
      <section id="mainInformations">
        <Formation />
        <Entreprise />
      </section>
      <button>
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-suit-heart-fill" viewBox="0 0 24 24">
        <path d="M12.279,8.833L12,9.112l-0.279-0.279c-1.088-1.088-2.813-1.088-3.906,0c-1.089,1.094-1.089,2.818,0,3.907L12,16.926 l4.186-4.186c1.089-1.088,1.089-2.813,0-3.907C15.092,7.745,13.367,7.745,12.279,8.833z"></path><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path>
      </svg>
        <b>{T["passions"][language]}</b></button>
      <section id="mainPassions">
        <DevInfo />
        <JeuVideo />
        <LevelDesign />
      </section>
    </main>
  );
}

export default Accueil;
