import React from 'react';
import ReactDOM from 'react-dom';
import Hub from './Hub';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux';
import reducer from "./reducer/reducer";
import { Provider } from "react-redux";

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() // to work with the redux dev tools extension
);

ReactDOM.render(
  <Provider store={store}>
    <Hub />
  </Provider>,
  document.getElementsByTagName("BODY")[0]
);

reportWebVitals();
