import React, { useEffect } from 'react';
import './../styles/main.scss';

const Background = () => {
  return (
    null
    );

}

export default Background;
