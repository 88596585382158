import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../styles/projet.scss';


const Projets = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <main id="projets">
      <h1>{T['projets1'][language]}</h1>
      <section>
        <section>
          <img src="./images/preview/jjoo.jpg" alt="image jjoo"></img>
          <article>
            <h2>JJOo!</h2>
            <p><b>2020 {T['projets2today'][language]}</b> - <i className="green bolder">{T['projets3active'][language]}</i></p>
            <p>{T['projets4'][language]}</p>
            <img className="logoProject" src="./images/logo/jjoo.png" alt="jjoo"></img>
            <Link to="/Projects/jjoo">{T['projets5discover'][language]}</Link>
          </article>
        </section>
  
        <section>
          <img src="./images/preview/dranora2.jpg" alt="image jjoo"></img>
          <article>
            <h2>Dranora</h2>
            <p><b>2021 {T['projets2today'][language]}</b> - <i className="green bolder">{T['projets3active'][language]}</i></p>
            <p>{T['projets7'][language]}</p>
            <img className="logoProject" src="./images/logo/dranora.png" alt="dranora"></img>
            <Link to="/Projects/dranora">{T['projets5discover'][language]}</Link>
          </article>
        </section>

        <section>
          <img src="./images/preview/dragonica2.jpg" alt="image jjoo"></img>
          <article>
            <h2>Polemos</h2>
            <p><b>2019, 2021</b> - <i className="red bolder">{T['projets3ended'][language]}</i></p>
            <p>{T['projets8'][language]}</p>
            <img className="logoProject" src="./images/logo/polemos.png" alt="dranora"></img>
            <Link to="/Projects/polemos">{T['projets5discover'][language]}</Link>
          </article>
        </section>


        <aside id="revoWood" className="blackwood"></aside>
      </section>

    </main>
  );
}

export default Projets;
