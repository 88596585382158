const traductions = {
    "Home": ["Accueil", "Home", ""],
    "CV": ['Curriculum Vitae', "Curriculum Vitae", "Curricum Vitae"],
    "Projets": ['Projets', 'Projects', 'Projekty'],
    "Contact": ['Contact', 'Contact', 'Contact'],
    "Language": ['Sélectionner votre langue', 'Choose your language', 'Wybierz twoj jezyk'],
    "ThomasPazder": ['Thomas Pazder', 'Thomas Pazder', 'Thomas Pazder'],
    "Discover": ['Découvrez mon monde', 'Discord my world', 'Découvrez mon monde'],
    "dranoraTitlePresentation": ['Présentation', 'Presentation', 'Prezentacja'],
    "dranoraIs": ['est un', 'is a', 'jest'],
    "dranoraGame": ['développé par une équipe d\'amis ayant la passion du jeu vidéo en commun.', 'developed by a team of friends who share a passion for video games.', 'opracowany przez zespół przyjaciół, których łączy pasja do gier.'],
    "dranoraStarted": ['Commencé en 2021, le projet a été de construire un jeu vidéo reprenant les graphismes qui nous font rêver:', 'Started in 2021, the project was to build a video game using the graphics that make us dream:', 'Rozpoczęty w 2021 roku projekt polegał na stworzeniu gry z wykorzystaniem grafiki, która nas marzy:'],
    "dranoraObjectif": ['L\'objectif sera de créer son personnage, choisir sa classe et vivre une expérience', 'Create a character, choose your class and live an experience: that\'s the goal', 'Celem będzie stworzenie jego postaci, wybranie jego klasy i przeżycie doświadczenia'],
    "dranoraGameWillBe": ['Le jeu sera', 'The game will be', 'Gra będzie'],
    "free": ['gratuit', 'free', 'za darmo'],
    "en": ['en', 'in', 'w'],
    "and": ['et', 'and', 'i'],
    "to": ['à', 'to', 'do'],
    "with": ['avec', 'with', 'z'],
    "accessible": ['accessible sur tous les ordinateurs', 'accessible on all computers', 'dostępne na wszystkich komputerach'],
    "multi": ['multijoueur', 'multiplayer', 'multiplayer'],
    "dranoraObjectif2": ['en suivant le déroulé d\'une histoire vivante et captivante avec ses amis.', 'by following the unfolding of a lively and captivating story with friends.', 'śledząc z przyjaciółmi rozwijającą się żywą i wciągającą historię.'],
    "dranoraChibi": ['Chibi manga', 'Chibi manga', 'manga chibi'],
    "accessiblePlus": ['(windows, linux, macOS) respectant les configurations requises (voir le descriptif technique en bas).', '(windows, linux, macOS) respecting the required configurations (see the technical description below).', '(windows, linux, macOS) z zachowaniem wymaganych konfiguracji (patrz opis techniczny poniżej).'],
    "historyTitle": ['Historique', 'Historical', 'Opis chronologiczny'],
    "dranoraH1": ['Dranora est un projet qui a son commencement était une grande inspiration de notre jeu favoris: Dragonica.', 'Dranora is a project that started out as a big inspiration for our favorite game: Dragonica.', 'Dranora to projekt, który powstał jako wielka inspiracja dla naszej ulubionej gry: Dragonica.'],
    "dranoraH2": ['Au départ, le projet ne suivait aucune charte graphique précise et les directions étaient imprécises.', 'Initially, the project did not follow any precise graphic charter and the directions were imprecise.', 'Początkowo projekt nie był zgodny z żadnym precyzyjnym schematem graficznym, a wskazówki były nieprecyzyjne.'],
    "dranoraH3": ['(Début du projet)', '(Project beginning)', '(Początek projektu)'],
    "dranoraH4": ['Le projet a rapidement évolué et nous en sommes arrivé à un résultat correspondant au graphisme que nous visions. Nous nous rendons compte que nous avons encore beaucoup à apprendre mais chaque jour le projet évolue encore et encore !', 'The project evolved quickly and we arrived at a result corresponding to the design we were aiming for. We realize that we still have a lot to learn but every day the project evolves again and again!', 'Projekt ewoluował szybko i osiągnęliśmy rezultat odpowiadający projektowi, do którego dążyliśmy. Zdajemy sobie sprawę, że wciąż musimy się wiele nauczyć, ale każdego dnia projekt ewoluuje na nowo!'],
    "dranoraH5": ['Une fois que la paterne graphique a été normalisée, nous nous sommes attaqué aux systèmes, et à la modélisation de nouveaux objets, de nouveaux décors, de nouveaux personnages, monstres, etc.', 'Once the graphics were normalized, we got down to the systems, and modeling new objects, new settings, new characters, monsters, etc.', 'Po znormalizowaniu grafiki zabraliśmy się za systemy i modelowanie nowych obiektów, nowych ustawień, nowych postaci, potworów itp.'],
    "technique": ['Description Technique', 'Technical description', 'Opis techniczny'],
    "dranoraH7": ['Date de sortie prévue: imprévu', 'Planned release date: unexpected', 'Planowana data premiery: nieprzewidziana'],
    "dranoraH8": ['Genre du jeu: MMORPG', 'Game genre: MMORPG', 'Gatunek gry: MMORPG'],
    "dranoraH9": ['Mode de jeu: Multijoueur', 'Game Mode: Multiplayer', 'Tryb gry: wieloosobowy'],
    "dranoraH10": ['Taille du jeu: indéterminée', 'Game size: undetermined', 'Rozmiar gry: nieokreślony'],
    "dranoraH11": ['téléchargement: indisponible', 'download: unavailable', 'pobieranie: niedostępne'],
    "dranoraH12": ['Langue: français, anglais', 'Language: French, English', 'Język: francuski, angielski'],
    "dranoraH13": ['Contrôle: Clavier, Souris', 'Control: Keyboard, Mouse', 'Sterowanie: klawiatura, mysz'],
    "dranoraH14": ['Système Opérationnelle', 'Operational System', 'System operacyjny'],
    "dranoraH15": ['Exigences supplémentaires', 'Additional requirements', 'Dodatkowe wymagania'],
    "website": ['Site internet', 'Website', 'Strona internetowa'],
    "jjooH1": ['est un projet de développement de jeu vidéo formé par une équipe d’étudiants et d’anciens étudiants de l’université Jean Jaurès. En décembre 2019, l’idée était simplement de réaliser un mini jeu dans l\'Université. Comprenant l\'enthousiasme de notre entourage, nous avons eu pour objectif d\'en faire un jeu vidéo construit.', 'is a video game development project formed by a team of students and alumni from Jean Jaurès University. In December 2019, the idea was simply to make a mini game in the University. Understanding the enthusiasm of those around us, our goal was to make it a constructed video game.', 'to projekt tworzenia gier utworzony przez zespół studentów i absolwentów Uniwersytetu Jean Jaurès. W grudniu 2019 roku pomysł polegał na zrobieniu mini-gry na uniwersytecie. Rozumiejąc entuzjazm otaczających nas osób, naszym celem było uczynienie z niej skonstruowanej gry.'],
    "step": ['Etape', 'Step', 'Krok'],
    "jjooH0": ['L\'université en jeu vidéo ?!', 'University in video games ?!', 'Uniwersytet w grze ?!'],
    "jjooH2": ['La première version de JJOo! sera donc un', 'The first version of JJOo! will therefore be a', 'Pierwsza wersja JJOo! będzie zatem'],
    "jjooH3": ['jeu d\'horreur', 'horror game', 'horror'],
    "jjooH4": ['dans lequel des mystères devront être compris et résolus.', 'in which mysteries will have to be understood and solved.', 'w którym tajemnice będą musiały zostać zrozumiane i rozwiązane.'],
    "jjooH5": ['Début janvier 2020, il s’agissait simplement de modéliser l\'Université en 3D. Le projet JJOo! à ses débuts, ne ressemblait pas à grand chose...', 'In early January 2020, it was simply a question of modeling the University in 3D. The JJOo project! in its early days, didn\'t look like much ...', 'Na początku stycznia 2020 roku była to po prostu kwestia modelowania Uniwersytetu w 3D. Projekt JJOo! na początku nie wyglądał na dużo ...'],
    "jjooH6": ['Motivé par les proches, une poussée d\'adrénaline a donné envie de continuer ce projet. Une équipe s’est construite et des investissements ont été réalisés (nom de domaine, site internet, assets, etc). Le projet s\'est donc poursuivi en plusieurs axes de travail.', 'Motivated by loved ones, an adrenaline rush made people want to continue this project. A team was built and investments were made (domain name, website, assets, etc.). The project therefore continued in several areas of work.', 'Zmotywowani przez bliskich, przypływ adrenaliny sprawił, że ludzie chcieli kontynuować ten projekt. Zbudowano zespół i dokonano inwestycji (nazwa domeny, strona internetowa, aktywa itp.). W związku z tym projekt był kontynuowany w kilku obszarach prac.'],
    "jjooH7": ['Développement du jeu', 'Game development', 'Produkcja gry'],
    "jjooH8": ['Ajustement des performances', 'Performance tuning', 'Podnoszenie wydajności'],
    "jjooH9": ['Démonstration ouverte', 'Otwarty pokaz', 'Open demonstration'],
    "jjooH10": ['Test en ligne', 'Online test', 'Test online'],
    "jjooH11": ['Chaque axe est une suite logique imaginée afin de pouvoir suivre le déroulement le plus motivant possible.', 'Each axis is a logical sequence imagined in order to be able to follow the most motivating development possible.', 'Każda oś jest logiczną sekwencją wyobrażoną w celu umożliwienia nadążania za najbardziej motywującym rozwojem.'],
    "jjooH12": ['L\'étape 1 est la partie du', 'Step 1 is the part of', 'Krok 1 jest częścią'],
    "jjooH13": ['car l’environnement de l’université Jean Jaurès devait être réalisé afin de pouvoir imaginer un quelconque projet sur celui-ci. Normalement, le', 'because the environment of the University Jean Jaurès had to be realized in order to be able to imagine any project on it. Normally the', 'ponieważ środowisko uniwersytetu Jean Jaurès musiało zostać zrealizowane, aby móc sobie wyobrazić jakikolwiek projekt na nim. Zwykle'],
    "jjooH14": ['qui est l\'étape 2 de notre projet est le commencement de tout projet de création de jeu vidéo mais comme nous savions que ce projet allait être construit autour de l’université, notre alternative était la meilleure. La partie du Level design (Création du niveau / de l\'environnement) est pour ce projet l\'élément le plus important.', 'which is step 2 of our project is the beginning of any video game creation project but since we knew that this project was going to be built around the university, our alternative was the best. The Level design part is the most important element for this project.', 'który jest krokiem 2 naszego projektu jest początkiem każdego projektu tworzenia gier wideo, ale ponieważ wiedzieliśmy, że ten projekt będzie budowany wokół uniwersytetu, nasza alternatywa była najlepsza. Część projektowa poziomu jest najważniejszym elementem tego projektu.'],
    "jjooH15": ['Après avoir terminé l’environnement sur le point visuel, la partie sur le Game design qui contient la construction de l’univers et l’imagination du style du jeu est indispensable pour son développement. C\'est à ce moment-là que nous avons déterminé qu\'il s\'agissait d\'un jeu d\'horreur.', '', ''],
    "La": ['La', 'The', ''],
    "jjooH17": ['phase de développement', 'development phase', 'faza rozwoju'],
    "jjooH18": ['du jeu peut ensuite commencer, ça sera la partie la plus longue mais la plus intéressante. Le développement du jeu comprend l\'', 'of the game can then start, it will be the longest part but the most interesting. Game development includes', 'gry może się wtedy rozpocząć, będzie to najdłuższa, ale najciekawsza część. Tworzenie gier obejmuje'],
    "jjooH19": ['qui se fera en même temps afin de créer les menus (écran de connexion, fin, feuilles, etc). Une fois que le jeu aura une version pouvant être testée, il y aura la question des performances du jeu: le projet doit pouvoir viser un large public avec tous les moyens informatiques minimum possibles. Evidemment, ce jeu ne pourra pas laisser un ordinateur de travail sans ressentir des décalages en jeu.', 'which will be done at the same time in order to create the menus (login screen, end, sheets, etc.). Once the game has a version that can be tested, there will be the question of the performance of the game: the project must be able to target a large audience with all the minimum computer resources possible. Obviously, this game will not be able to leave a work computer without feeling some lags in the game.', 'co zostanie zrobione w tym samym czasie w celu stworzenia menu (ekran logowania, koniec, arkusze itp.). Gdy gra będzie miała wersję, którą można przetestować, pojawi się kwestia wydajności gry: projekt musi być w stanie dotrzeć do dużej publiczności przy użyciu wszystkich minimalnych możliwych zasobów komputera. Oczywiście ta gra nie będzie mogła opuścić komputera roboczego bez odczuwania pewnych opóźnień w grze.'],
    "jjooH20": ['Une fois que les performances seront réalisées de manière à diminuer les charges graphiques, JJOo! pourra être proposé en “démo” publiquement, avec un lien de téléchargement.', 'Once the performance is achieved in such a way as to reduce the graphics load, JJOo! may be offered as a “demo” publicly, with a download link.', 'Po osiągnięciu wydajności w taki sposób, aby zmniejszyć obciążenie grafiki, JJOo! mogą być oferowane publicznie jako „demo”, z linkiem do pobrania.'],
    "jjooH21": ['Pour finir, les tests en ligne pourront voir le jour afin de corriger les bugs, les imperfections, et tous les imprévus pouvant nuir à la qualité du joueur. Suite à leurs résolutions, une date de sortie pourra être discutée.', 'Finally, online tests will be able to see the light of day in order to correct bugs, imperfections, and all the unforeseen events that could affect the quality of the player. Following their resolutions, a release date can be discussed.', 'Wreszcie testy online będą mogły ujrzeć światło dzienne, aby poprawić błędy, niedoskonałości i wszystkie nieprzewidziane zdarzenia, które mogą wpłynąć na jakość gracza. Po ich postanowieniach można omówić datę premiery.'],
    "jjooH22": ['De mois en mois, nous avons réussi à rendre le jeu de plus en plus réaliste et nous sommes persuadés que le jeu deviendra de plus en plus intéressant. En 2020, il n\'était pas question de jeu d\'Horreur, et les images du dessous vous montreront l\'évolution continue du projet.', 'Month after month, we have managed to make the game more and more realistic and we are convinced that the game will become more and more interesting. In 2020, there was no Horror game, and the images below will show you the continued evolution of the project.', 'Z miesiąca na miesiąc udało nam się uczynić grę coraz bardziej realistyczną i jesteśmy przekonani, że będzie ona stawała się coraz ciekawsza. W 2020 roku nie było horroru, a poniższe zdjęcia pokazują dalszą ewolucję projektu.'],
    "jjooH23": ['Genre du jeu: Horreur', 'Game Genre: Horror', 'Gatunek gry: Horror'],
    "jjooH24": ['Mode de jeu: 1 joueur', 'Game mode: 1 player', 'Tryb gry: 1 gracz'],
    "jjooH25": ['Langue: français', 'Language: french', 'Jezyk: francuzki'],
    "polemosH0": ['Tournois et Evenements', 'Tournaments and Events', 'Turnieje i wydarzenia'],
    "polemosH1": ['est une application Web complémentaire au jeu vidéo', 'is a complementary web application to video games', 'to komplementarna aplikacja internetowa do gier wideo'],
    "polemosH1p1": ['développé dans le but de pouvoir organiser et gérer des systèmes automatisés reliés au jeu directement.', 'developed with the aim of being able to organize and manage automated systems directly linked to the game.', 'opracowany w celu organizowania i zarządzania zautomatyzowanymi systemami bezpośrednio powiązanymi z grą.'],
    "polemosH2": ['Pour la partie back end,', 'For the back-end part,', 'Jeśli chodzi o back-end,'],
    "polemosH3": ['a été le choix utilisé.', 'was the choice used.', 'był użyty wybór.'],
    "polemosH4": ['Pour la partie front end,', 'For the front-end part,', 'Jeśli chodzi o front-end,'],
    "polemosH5": ['en complément.', 'in addition.', 'dodatkowo.'],
    "polemosH6": ['Création et connexion de compte sécurisé relié au jeu avec vérification par mail', 'Creation and connection of a secure account linked to the game with verification by email', 'Utworzenie i połączenie bezpiecznego konta połączonego z grą z weryfikacją przez e-mail'],
    "polemosH7": ['Création, participation aux tournois et aux événements', 'Creation, participation in tournaments and events', 'Kreacja, udział w turniejach i imprezach'],
    "polemosH8": ['Système d\'achats automatiques intégrés au jeu', 'In-game automatic purchasing system', 'Automatyczny system zakupów w grze'],
    "polemosH9": ['Création d\'un système de parties classées avec une détection des matchs en jeu', 'Creation of a ranked game system with in-game detection', 'Stworzenie systemu gier rankingowych z wykrywaniem w grze'],
    "polemosH10": ['Système de pass permettant d\'ajouter des quêtes et attribution automatique de récompenses', 'Pass system for adding quests and automatic awarding of rewards', 'System przepustek do dodawania zadań i automatycznego przyznawania nagród'],
    "polemosH11": ['Systèmes', 'Systems', 'Systemy'],
    "polemosH12": ['Système d\'Authentification', 'Authentication System', 'System uwierzytelniania'],
    "polemosH13": ['Afin de profiter des différentes fonctionnalités de l\'application, l\'utilisateur doit s\'inscrire. Lorsque l\'inscription se réalise sur l\'application Web, la partie back end s\'occupe aussi de créer le compte en jeu. Pour ce faire, un mail de confirmation est envoyé à l\'utilisateur. Une fois que le mail est confirmé, il faut entrer le code de validation sur le profil afin de profiter pleinement des fonctionnalités. L\'utilisateur peut alors se connecter et bénéficier des fonctionnalités: créer des personnages en jeu sur l\'application Web, acheter des cosmétiques pour le jeu, participer aux tournois, créer des équipes, etc... ', 'In order to take advantage of the different features of the application, the user must register. When registration is done on the Web application, the back end part also takes care of creating the in-game account. To do this, a confirmation email is sent to the user. Once the email is confirmed, you must enter the validation code on the profile in order to take full advantage of the features. The user can then log in and take advantage of the features: create in-game characters on the web application, buy in-game cosmetics, participate in tournaments, create teams, etc.', 'Aby móc korzystać z różnych funkcji aplikacji, użytkownik musi się zarejestrować. Gdy rejestracja jest wykonywana w aplikacji internetowej, część zaplecza zajmuje się również tworzeniem konta w grze, w tym celu wysyłana jest do użytkownika wiadomość e-mail z potwierdzeniem. Po potwierdzeniu adresu e-mail musisz wprowadzić kod weryfikacyjny w profilu, aby w pełni wykorzystać funkcje. Użytkownik może następnie zalogować się i skorzystać z funkcji: tworzyć postacie w grze w aplikacji internetowej, kupować kosmetyki w grze, brać udział w turniejach, tworzyć drużyny itp.'],
    "polemosH14": ['Système de parties classées', 'Ranked games system', 'System gier rankingowych'],
    "polemosH15": ['Afin d\'ajouter du piment sur le jeu Dragonica, un système de classement sur le site permet aux joueurs de pouvoir s\'affronter via un nouveau système: les parties classées disponible sur l\'application Web. Les joueurs peuvent à partir de là se rejoindre par le biais d\'un matchmaking. Une fois le match trouvé, un affrontement entre les joueurs est poussé en jeu et est relié à l\'application Web. L\'application Polemos s\'occupe ensuite de répartir les récompenses.', 'To add spice to the Dragonica game, a ranking system on the site allows players to compete against each other through a new system: ranked games available on the web application. Players can then join each other through matchmaking. Once the match is found, a clash between the players is pushed into the game and is linked to the web application. The Polemos application then takes care of distributing the rewards.', 'Aby dodać pikanterii grze Dragonica, system rankingowy na stronie pozwala graczom rywalizować ze sobą poprzez nowy system: gry rankingowe dostępne w aplikacji internetowej. Gracze mogą wtedy łączyć się ze sobą poprzez dobieranie graczy. Po znalezieniu meczu starcie między graczami zostaje przeniesione do gry i jest połączone z aplikacją internetową. Aplikacja Polemos zajmie się wówczas dystrybucją nagród.'],
    "polemosH16": ['Système d\'Achats', 'Purchasing System', 'System zakupów'],
    "polemosH17": ['Pour que les joueurs puissent jouer avec de jolies tenues et qu\'ils puissent avoir une jolie apparence, l\'application Polemos devait contenir un système d\'achats automatique.', 'In order for players to be able to play with cute outfits and to have a pretty appearance, the Polemos app had to contain an automatic shopping system.', 'Aby gracze mogli bawić się uroczymi strojami i ładnie wyglądać, aplikacja Polemos musiała zawierać automatyczny system zakupów.'],
    "polemosH18": ['Système de Missions/Pass', 'Missions / Pass System', 'Misje / system przepustek'],
    "polemosH19": ['Afin de divertir et motiver tous les joueurs, des quêtes et missions doivent être effectuées pour obtenir des récompenses gratuites ou payantes !', 'In order to entertain and motivate all players, quests and missions must be completed to get free or paid rewards!', 'Aby bawić i motywować wszystkich graczy, misje i misje muszą zostać ukończone, aby otrzymać darmowe lub płatne nagrody!'],
    "polemosH20": ['Autres...', 'Others...', 'Inny...'],
    "polemosH21": ['La liste serait encore longue !', 'The list is long !', 'Lista jest długa!'],
    "passions": ['Mes passions...', 'My passions...', 'Moje pasje ...'],
    "contactme": ['Contactez moi', 'Contact me', 'Skontaktuj się ze mną'],
    "clickhere": ['Cliquez ici', 'Click here', 'Kliknij tutaj'],
    "devinfo1": ['Développement Logiciels &  d\'Applications Web', 'Software and Web Application Development', 'Tworzenie oprogramowania i aplikacji internetowych'],
    "devinfo2": ['Développement Logiciels', 'Software Development', 'Rozwój aplikacji'],
    "devinfo3": ['Application spécialisée pour les achats (Capgemini Engineering) - Python', 'Specialized application for procurement (Airbus Defense and Space) - Python', 'Specjalistyczna aplikacja do zakupów (Capgemini Engineering) - Python'],
    "devinfo4": ['QuestGenerator: Application de génération de XML - Python', 'Quest Generator: XML Generator Application - Python', 'QuestGenerator: Aplikacja do generowania XML - Python'],
    "devinfo5": ['Développement d\'Applications Web', 'Web Application Development', 'Tworzenie aplikacji internetowych'],
    "devinfo6": ['Polemos: Application spécialisée dans la gestion de tournoi et de mode de jeu pour le jeu "Dragonica" - Front End: React.js, Redux ; Back End: Node.js ; SGBD: SQLserver', 'Polemos: Application specialized in tournament and game mode management for the game "Dragonica" - Front End: React.js, Redux; Back End: Node.js; DBMS: SQLserver', 'Polemos: Aplikacja specjalizująca się w zarządzaniu turniejami i trybami gry dla gry "Dragonica" - Front End: React.js, Redux; Back End: Node.js; DBMS: SQLserver'],
    "devinfo7": ['Système de Matchmaking', 'Matchmaking System', 'System kojarzeń'],
    "devinfo8": ['Système de Paiement automatique', 'Automatic payment system', 'Automatyczny system płatności'],
    "devinfo9": ['Système d\'Inscription et de Connexion sécurisée', 'Secure Registration and Login System', 'Bezpieczny system rejestracji i logowania'],
    "devinfo10": ['Liaison avec le jeu et interaction en direct', 'Link and live interaction with game', 'Tworzenie więzi z grą i interakcja na żywo'],
    "devinfo11": ['Application web spécialisée pour les achats (Capgemini Engineering) - html, css, PHP, React.js', 'Specialized web application for procurement (Airbus Defense and Space) - html, css, PHP, React.js', 'Specjalistyczna aplikacja internetowa do zakupów (Capgemini Engineering) - html, css, PHP, React.js'],
    "devinfo12": ['League of Stones: Application web introduisant un jeu de cartes sur l\'univers du jeu League of Legends (Projet Universitaire)', 'League of Stones: Web application introducing a card game to the League of Legends game universe (University Project)', 'League of Stones: aplikacja internetowa wprowadzająca grę karcianą do uniwersum League of Legends (projekt uniwersytecki)'],
    "entreprise1": ['Full Stack Developer  Capgemini Engineering', 'Full Stack Developer  Capgemini Engineering', 'Full Stack Developer  w Capgemini Engineering'],
    "entreprise2": ['', '', ''],
    "entreprise3": ['', '', ''],
    "entreprise4": ['Entreprise...', 'Company...', 'Firma...'],
    "forma1": ['Formation...', 'Schooling...', 'Szkolenie...'],
    "forma2": ['Master ICE - Informatique Collaborative en Entreprise', 'Master ICE - Collaborative Computing in Business', 'Master ICE - Informatyka współpracowa w biznesie'],
    "forma3": ['Formation en alternance spécialisée dans le développement Informatique et la gestion de projet', 'Work-study training specializing in IT development and project management', 'Szkolenie przygotowujące do pracy specjalizujące się w rozwoju IT i zarządzaniu projektami'],
    "game1": ['Développement de Jeux Vidéos', 'Video Games Development', 'Rozwój gier'],
    "game2": ['JJOo! L\'Université Jean-Jaurès en jeu vidéo', 'JJOo! Jean-Jaurès University in video games', 'JJOo! Jean-Jaurès University w grze ?!'],
    "game3": ['Jeu d\'horreur où l\'Université est devenu un bain de sang. Résolvez le mystère!', 'Horror game where the University has become a bloodbath. Solve the mystery!', 'Gra grozy, w której uniwersytet stał się rozlewem krwi. Rozwiązać zagadkę!'],
    "game4motor": ['Moteur de jeu:', 'Game engine:', 'Silnik gry:'],
    "game5lang": ['Langage utilisé: ', 'Language: ', 'Używany język: '],
    "game6": ['MMORPG s\'inspirant des jeux favoris pour proposer un monde Chibi/Manga', 'MMORPG inspired by favorite games to offer a Chibi / Manga world', 'MMORPG inspirowane ulubionymi grami oferującymi świat Chibi / Manga'],
    "game7": ['Développement de scripts', 'Development of scripts', 'Rozwój wyzwalaczy, skryptu'],
    "game8": ['Développement de plugins pour un bot', 'Development of plugins for a bot', 'Rozwój wtyczek dla bota'],
    "leveldesign1": ['Logiciels utilisés: ', 'Softwares: ', 'Użyte oprogramowanie: '],
    "leveldesign2": ['Reconstitution partielle de l\'Université Toulouse II Jean Jaurès', 'Partial reconstruction of the University of Toulouse II Jean Jaurès', 'Częściowa rekonstrukcja Uniwersytetu w Tuluzie II Jean Jaurès'],
    "leveldesign3": ['Création de plusieurs levels (environnements): ville, forêt, plaines, ...', 'Creation of several levels (environments): city, forest, plains, ...', 'Tworzenie kilku poziomów (środowisk): miasto, las, równiny, ...'],
    "leveldesign4": ['Création de plus de 200 levels différentes: villes, donjons, parcours, ...', 'Creation of more than 200 different levels: cities, dungeons, courses, ...', 'Stworzenie ponad 200 różnych poziomów: miasta, lochy, tory, ...'],
    "downloadcv": ['Télécharger mon CV', 'Download my CV', 'Pobierz moje CV'],
    "bootstrash": ['Un caméléon qui adore les nouveaux challenges !', 'A chameleon that loves new challenges!', 'Kameleon, który uwielbia nowe wyzwania!'],
    "projets1": ['Mes Projets', 'My projects', 'Moje projekty'],
    "projets2today": ['à aujourd\'hui', 'to today', 'do dziś'],
    "projets3active": ['Projet Actif', 'Active Project', 'Aktywny projekt'],
    "projets4": ['JJOo! est un projet personnel de développement de jeu vidéo Horreur sur le thème de l\'université Toulouse II Jean Jaurès.', 'JJOo! is a personal Horror game development project on the theme of Toulouse II Jean Jaurès University.', 'JJOo! to osobisty projekt rozwoju gier typu Horror na temat Toulouse II Jean Jaurès University.'],
    "projets5discover": ['Découvrir', 'Discover', 'Odkryc'],
    "projets3ended": ['Projet terminé', 'Project Ended', 'Projekt zakończony'],
    "projets7": ['Dranora est un jeu 3D MMORPG Action dans un thème Chibi/Manga. Choisissez votre classe, créez votre histoire et mettez le destin de votre côté.', 'Dranora is a 3D Action MMORPG game in a Chibi / Manga theme. Choose your class, create your story and put fate on your side.', 'Dranora to 3D gra akcji MMORPG utrzymana w tematyce Chibi / Manga. Wybierz klasę, stwórz swoją historię i postaw los po swojej stronie.'],
    "projets8": ['Polemos est un système automatisé de tournoi et de système de parties classées pour le jeu "Dragonica". Système relié au jeu.', 'Polemos is an automated tournament and ranked game system for the game "Dragonica". System linked to the game.', 'Polemos to zautomatyzowany system rozgrywek turniejowych i rankingowych do gry „Dragonica”. System powiązany z grą.'],
}
const iniatialState = {
    T : traductions,
    language: 0
};
const reducer =  (state = iniatialState, action) => {
    switch (action.type) {

        case "LANGUAGE":
            return{
                ...state,
                    T: traductions,
                    language: action.language
                
        };
        default:
            return state
    };
};
export default reducer;