import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../../styles/projet.scss';


const Jjoo = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <main id="projet">
      <h1>JJOo! - {T["jjooH0"][language]}</h1>
      <section>
        <img src="./../images/logo/jjoo.png" alt="jjoo"></img>
        <nav>
          <ul>
            <li><a href="https://jjoo.fr/" target="_blank">{T["website"][language]}</a></li>
            <li><a href="https://jjoo.fr/" target="_blank">@jjoofr</a></li>
            <li><a href="https://jjoo.fr/" target="_blank">marketing@jjoo.fr</a></li>
            <li><a href="https://www.youtube.com/channel/UCthhQfFWaCF0zAnxmnKH_4Q/" target="_blank">YouTube</a></li>
          </ul>
        </nav>
        <article>
          <h2>{T["dranoraTitlePresentation"][language]}</h2>
          <p>
            <strong>JJOo!</strong> {T["jjooH1"][language]}<br></br>
            {T["jjooH2"][language]} <strong>{T["jjooH3"][language]}</strong> {T["jjooH4"][language]}
          </p>

          <p>
          {T["dranoraGameWillBe"][language]} <strong>{T["free"][language]}</strong>, {T["en"][language]} <strong>3D</strong> {T["and"][language]} <strong>{T["accessible"][language]}</strong>
          {T["accessiblePlus"][language]}
          </p>        
        </article>
        <article>
          <h2>{T["historyTitle"][language]}</h2>
          <p>
          {T["jjooH5"][language]}

          <img src="./../images/preview/jjoo/1.png" alt="image du jeu à ses débuts"></img>
          <br></br><br></br>
          {T["jjooH6"][language]}
          </p>

          <ul>
            <li>{T["step"][language]} 1 - Level Design</li>
            <li>{T["step"][language]} 2 - Game Design</li>
            <li>{T["step"][language]} 3 - {T["jjooH7"][language]}</li>
            <li>{T["step"][language]} 4 - {T["jjooH8"][language]}</li>
            <li>{T["step"][language]} 5 - {T["jjooH9"][language]}</li>
            <li>{T["step"][language]} 6 - {T["jjooH10"][language]}</li>
          </ul>

          <p>
          {T["jjooH11"][language]}<br></br>
          {T["jjooH12"][language]} <strong>Level Design</strong> {T["jjooH13"][language]} <b>Game Design</b> {T["jjooH14"][language]} <br></br>
          {T["jjooH15"][language]}
          </p>
          <p>
          {T["La"][language]} <b>{T["jjooH17"][language]}</b> {T["jjooH18"][language]}<b>UI design</b>{T["jjooH19"][language]} <br></br><br></br> {T["jjooH20"][language]}
          <br></br><br></br>{T["jjooH21"][language]}
          </p>
          <img src="./../images/preview/jjoo/2.png" alt="image continue du dev jjoo"></img>
          <p>
          <i>(2020)</i><br></br><br></br>
          {T["jjooH22"][language]}
          </p>
        </article>
        <article>
          <h2>{T["technique"][language]}</h2>
          <ul>
            <li>{T["dranoraH7"][language]}</li>
            <li>{T["jjooH23"][language]}</li>
            <li>{T["jjooH24"][language]}</li>
            <li>{T["dranoraH10"][language]}</li>
            <li>{T["dranoraH11"][language]}</li>
            <li>{T["jjooH25"][language]}</li>
            <li>{T["dranoraH13"][language]}</li>
          </ul>

          <table>
            <tr>
              <th>{T["dranoraH14"][language]}</th><td>Windows</td><td>Universal Windows Platform</td><td>macOS</td><td>Linux</td>
            </tr>
            <tr>
              <th>Version</th><td>Windows 7, 10, ...</td><td>Xbox One, Hololens, ...</td><td>Sierra 10.12+, ...</td><td>Ubuntu 16.04, Ubuntu 18.04, ...</td>
            </tr>
            <tr>
              <th>CPU</th><td>x86, x64</td><td>x86, x64</td><td>x64</td><td>x64</td>
            </tr>
            <tr>
              <th>Graphics</th><td>&gt; Direct X 10</td><td>&gt; Direct X 10</td><td>Intel & AMD GPUs</td><td>OpenGL 3.2+ / Vulkan</td>
            </tr>
            <tr>
              <th>{T["dranoraH15"][language]}</th><td></td><td>Windows 10 (64 bits), Visual Studio 2015 {T["with"][language]} C++ Tools Component +, Windows 10 SDK</td><td></td><td></td>
            </tr>
          </table>
        </article>

        <article className="gallery">
          <img src="/../images/preview/jjoo/5.png" alt="image continue du dev jjoo"></img>
          <img src="/../images/preview/jjoo/4.png" alt="image continue du dev jjoo"></img>
          <img src="/../images/preview/jjoo/3.jpg" alt="image continue du dev jjoo"></img>
        </article>

      </section>
      <aside id="revoWood" className="blackwood"></aside>
    </main>
  );
}

export default Jjoo;
