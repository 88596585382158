import React, { useEffect } from 'react';

const Footer = () => {
  return (
    <p id="footer">© 2021 Thomas "Tivise" Pazder</p>
    );

}

export default Footer;
