import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../styles/accueil.scss';

const LevelDesign = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <article id="leveldesign" className="passion">
      <section>
        <h4>Level Design</h4>
        <ul>
          <li><strong>JJOo!</strong>
            <ul>
              <li><b>2020-2021</b> {T["leveldesign2"][language]} <br></br>
              {T["leveldesign1"][language]} <b>Blender, Unity</b></li>
            </ul>
          </li>
          <li><strong>Dranora</strong>
            <ul>
              <li><b>2021</b> {T["leveldesign3"][language]} <br></br>
              {T["leveldesign1"][language]} <b>Blender, Unity</b></li>
            </ul>
          </li>
          <li><strong>Dragonica</strong>
            <ul>
              <li><b>2014-2021</b> {T["leveldesign4"][language]} <br></br>
              {T["leveldesign1"][language]} <b>Gamebryo</b></li>
            </ul>
          </li>
        </ul>
        {/* <button className="plus">En voir plus<svg className="svgRight downer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
</svg></button> */}
      </section>
      <aside className="svgBGl"><svg xmlns="http://www.w3.org/2000/svg" width="584" height="584" viewBox="0 0 24 24" fill="currentColor"><circle cx="6.5" cy="6.5" r="2.5"></circle><path d="M14 7L8.777 15.487 7 13 2 20 6 20 12 20 22 20z"></path></svg></aside>
      <img className="previewLeft" src="images/preview/dragonica.jpg"></img>
    </article>
  );
}

export default LevelDesign;
