import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../styles/accueil.scss';

const Presentation = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <section id="presentation">
        <h1>{T['ThomasPazder'][language]}</h1>
        <aside id="avatar">
          <img id="moi" src="images/avatar.jpg" alt="photo"></img>
          <img id="logo" src="images/logo/logo.png" alt=""></img>
        </aside>
        <a href="/cv.pdf" target="_blank">{T['downloadcv'][language]} <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 16L16 11 13 11 13 4 11 4 11 11 8 11z"></path><path d="M20,18H4v-7H2v7c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-7h-2V18z"></path></svg></a>
        <p id="citation">"{T['bootstrash'][language]}"</p>
    </section>
  );
}

export default Presentation;
