import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../styles/accueil.scss';

const Entreprise = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <section id="entreprise">
      <img className="logoHome" src="images/logo/airbusb.png" alt=""></img>
      <h3>{T["entreprise1"][language]}</h3>
      <p>{T["entreprise2"][language]}<br></br>{T["entreprise3"][language]}</p>
      <aside className="poppyPup">
        <p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="44" height="44" viewBox="0 0 24 24">
            <path d="M20 6h-3V4c0-1.103-.897-2-2-2H9C7.897 2 7 2.897 7 4v2H4C2.897 6 2 6.897 2 8v3h20V8C22 6.897 21.103 6 20 6zM9 4h6v2H9V4zM14 14h-4v-2H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-8V14z"></path>
          </svg>
          {T["entreprise4"][language]}<br></br><svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" fill="currentColor" class="bi bi-arrow-down-left" viewBox="0 0 16 16">
  <path d="M2 13.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 0-1H3.707L13.854 2.854a.5.5 0 0 0-.708-.708L3 12.293V7.5a.5.5 0 0 0-1 0v6z"/>
</svg></p>
      </aside>
    </section>
  );
}

export default Entreprise;
