import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './../../styles/projet.scss';


const Dranora = () => {
  const language = useSelector((state) => state.language);
  const T = useSelector((state) => state.T);

  return (
    <main id="projet">
      <h1>Dranora - 3D MMORPG ACTION</h1>
      <section>
        <img src="./../images/logo/dranora.png" alt="jjoo"></img>
        <nav>
          <ul>
            <li><a href="https://playdranora.fr/" target="_blank">{T["website"][language]}</a></li>
            <li><a href="https://www.youtube.com/channel/UC8mig2FK0Dw98Ckj4-KBq3g" target="_blank">YouTube</a></li>
          </ul>
        </nav>
        <article>
          <h2>{T["dranoraTitlePresentation"][language]}</h2>
          <p>
            <strong>Dranora</strong> {T["dranoraIs"][language]} <strong>MMORPG 3D Action</strong> {T["dranoraGame"][language]}<br></br> {T["dranoraStarted"][language]} <b>{T["dranoraChibi"][language]}</b>.
            {T["dranoraObjectif"][language]} <strong>{T["multi"][language]}</strong> {T["dranoraObjectif2"][language]}
          </p>

          <p>
            {T["dranoraGameWillBe"][language]} <strong>{T["free"][language]}</strong>, {T["en"][language]} <strong>3D</strong> {T["and"][language]} <strong>{T["accessible"][language]}</strong>
            {T["accessiblePlus"][language]}
          </p>        
        </article>
        <article>
          <h2>{T["historyTitle"][language]}</h2>
          <p>
          {T["dranoraH1"][language]}
          <br></br>
          {T["dranoraH2"][language]}
          <img src="./../images/preview/dranora/00.png" alt="image du jeu à ses débuts"></img>
          <i>{T["dranoraH3"][language]}</i><br></br><br></br>
          {T["dranoraH4"][language]}
          </p>
          <img src="./../images/preview/dranora/01.png" alt="image du jeu qui évolue 1"></img>
          <img src="./../images/preview/dranora/03.png" alt="image du jeu qui évolue 2"></img>
          <img src="./../images/preview/dranora/16s.png" alt="image du jeu qui évolue 3"></img>
          <img src="./../images/preview/dranora/modelisation.png" alt="image du jeu qui évolue 4"></img>
          <img src="./../images/preview/dranora/model.png" alt="image du jeu qui évolue 12"></img>
          <p>
          {T["dranoraH5"][language]}
          </p>
          <img src="./../images/preview/dranora/face.png" alt="image du jeu qui évolue 5"></img>
          <img src="./../images/preview/dranora/login.png" alt="image du jeu qui évolue 6"></img>
          <img src="./../images/preview/dranora/07.png" alt="image du jeu qui évolue 7"></img>
          <img src="./../images/preview/dranora/09s.png" alt="image du jeu qui évolue 8"></img>
          <img src="./../images/preview/dranora/10s.png" alt="image du jeu qui évolue 9"></img>
          <img src="./../images/preview/dranora/17s.png" alt="image du jeu qui évolue 10"></img>
          <img src="./../images/preview/dranora/27.png" alt="image du jeu qui évolue 11"></img>
        </article>
        <article>
          <h2>{T["technique"][language]}</h2>
          <ul>
            <li>{T["dranoraH7"][language]}</li>
            <li>{T["dranoraH8"][language]}</li>
            <li>{T["dranoraH9"][language]}</li>
            <li>{T["dranoraH10"][language]}</li>
            <li>{T["dranoraH11"][language]}</li>
            <li>{T["dranoraH12"][language]}</li>
            <li>{T["dranoraH13"][language]}</li>
          </ul>

          <table>
            <tr>
              <th>{T["dranoraH14"][language]}</th><td>Windows</td><td>Universal Windows Platform</td><td>macOS</td><td>Linux</td>
            </tr>
            <tr>
              <th>Version</th><td>Windows 7, 10, ...</td><td>Xbox One, Hololens,...</td><td>Sierra 10.12+, ...</td><td>Ubuntu 16.04, Ubuntu 18.04, ...</td>
            </tr>
            <tr>
              <th>CPU</th><td>x86, x64</td><td>x86, x64</td><td>x64</td><td>x64</td>
            </tr>
            <tr>
              <th>Graphics</th><td>&gt; Direct X 10</td><td>&gt; {T["to"][language]} Direct X 10</td><td>Intel & AMD GPUs</td><td>OpenGL 3.2+ / Vulkan</td>
            </tr>
            <tr>
              <th>{T["dranoraH15"][language]}</th><td></td><td>Windows 10 (64 bits), &gt;= Visual Studio 2015 {T["with"][language]} C++ Tools Component, Windows 10 SDK</td><td></td><td></td>
            </tr>
          </table>
        </article>

        <article className="gallery">
          <img src="./../images/preview/dranora/charselect.png" alt="création de personnage"></img>
          <img src="./../images/preview/dranora/uigame.png" alt="ui game"></img>
        </article>

      </section>
      <aside id="revoWood" className="blackwood"></aside>
    </main>
  );
}

export default Dranora;
